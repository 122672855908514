import AddToHomeScreen from "a2hs.js";

$(function() {
  $('#map li a').on("click", function() {
    var state = $(this).attr("id").toUpperCase();

    shosPoState(state);
  });

  $('.facebook-share').on('click', function() {
    FB.ui({
      method: 'share',
      href: window.location.href,
    }, function (response) { });

    return false;
  });

  $('.twitter-share').on('click', function() {
    var shareURL = "http://twitter.com/share?";

    var params = {
      url: window.location.href,
      text: $('title').text(),
      via: "kalinkacarvalho"
    }

    openShareWindow("http://twitter.com/share?", params);

    return false;
  });

  $('.google-share').on('click', function() {
    var params = {
      url: window.location.href
    }

    openShareWindow("https://plus.google.com/share?", params);

    return false;
  });

  $('.linkedin-share').on('click', function() {
    var params = {
      mini:true,
      url: window.location.href,
      title: $('title').text(),
      summary: "",
      source: ""
    }

    openShareWindow("https://www.linkedin.com/shareArticle?", params);

    return false;
  });

  $('#form_state').on("change", function() {
    shosPoState($(this).val());

    return false;
  });

  $('.qty-selector').on('change', function() {
    $.post('/loja/update', $('#form_order').serialize());
  });

  $('.mask-date').mask('00/00/0000');
  $(".mask-phone").mask("(99) 99999-9999")

  $('#form-downloads').on("submit", function(event) {
    window.open("/download/" + $('#arquivos').find(':selected').data('token'), "_blank");

    $.post('/download/user_info.js', { email: $('#email').val()} );

    return true;
  });

  if ($('.alert-header-notice').length > 0) {
    setTimeout(function() {
      $('.alert-header-notice').slideUp();
    }, 8000);
  }

  AddToHomeScreen({
    htmlContent: "<i class='fa fa-close ios-close-share-icon'></i>Instale meu app para fácil acesso ao site: toque em <img src='/icons/ios-share-icon.png' class='ios-share-icon' /> e depois em <strong>Adicionar a Tela de Início</strong>.",
    logoImage: "<img src='/icons/512.png' style='height: 50px' />"
  });
});

function openShareWindow(url, params) {
  for (var prop in params) url += '&' + prop + '=' + encodeURIComponent(params[prop]);

  var left = ($(window).width() / 2) - (550 / 2),
      top = ($(window).height() / 2) - (450 / 2);

  window.open(url, '', 'left='+left+',top='+top+',width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
}

function shosPoState(state) {
  $('#personal-container .row').hide();
  $('.po-state-' + state).show();

  if ($('.po-state-' + state).length === 0) {
    alert('Nenhuma encontrada nesse estado.');
  }
}

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    var serviceWorker;

    if (registration.installing) {
      serviceWorker = registration.installing;
      // console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      // console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      // console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});
